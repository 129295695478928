<template>
  <ul class="menu-nav">
    <router-link
      to="/akun-dan-role"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Akun & Role</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/aplikasi"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Aplikasi</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/informasi-program"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Informasi Program</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/survey-online"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Survey Online</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/visualisasi-data"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Visualisasi Data</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/perpustakaan-online"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Perpustakaan Online</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/peningkatan-kapasitas"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Peningkatan Kapasitas</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/penilaian-sistem-sehat"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-ripple
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Penilaian Sistem Sehat</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<style lang="scss" scoped>
.menu-item {
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
}
</style>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>

<template>
  <div
    id="kt_header_mobile"
    class="header-mobile c-header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <!-- <router-link to="/">
      <img alt="Logo" :src="headerLogo" class="logo-default max-h-30px mb-2" />
    </router-link> -->
    <!--end::Logo-->

    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-right"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <!-- <button
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
      </button> -->
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <!-- <button
        class="btn btn-icon btn-hover-transparent-white p-0 ml-3"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
      > -->
      <!-- <span class="svg-icon svg-icon-xl"> -->
      <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
      <!-- <inline-svg class="svg-icon" src="/media/svg/icons/General/User.svg" /> -->
      <!--end::Svg Icon-->
      <!-- </span> -->
      <!-- </button> -->
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<style lang="scss">
.c-header-mobile {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 65px;
  border-bottom: none;
  z-index: 1001;
  background-color: #ffffff;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.header-mobile .burger-icon span,
.header-mobile .burger-icon span::before,
.header-mobile .burger-icon span::after {
  background-color: rgba(31, 31, 31, 0.9) !important;
}

body[data-header-scroll="on"] {
  .c-header-mobile {
    box-shadow: 0px 5.14221px 12.8555px rgba(0, 0, 0, 0.3);
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  }
};
</script>

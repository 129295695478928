<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto bg-white"
    id="kt_aside"
    ref="kt_aside"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside Menu -->
    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
    >
      <div
        ref="kt_aside_menu"
        id="kt_aside_menu"
        class="aside-menu min-h-lg-800px"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        v-bind:class="asideMenuClass"
      >
        <img
          :src="`${baseAppPath}/media/sidebar-accent.png`"
          class="sidebar-accent"
        />

        <div class="user-btn-dd">
          <b-dropdown size="sm" variant="link" left no-caret no-flip>
            <template v-slot:button-content>
              <div class="user-btn" v-ripple>
                <img :src="`${baseAppPath}/media/user.png`" width="14px" />
              </div>
            </template>

            <b-dropdown-item link-class="text-center">{{
              name
            }}</b-dropdown-item>
            <b-dropdown-item
              @click="logout"
              link-class="text-center text-danger"
              >Logout</b-dropdown-item
            >
          </b-dropdown>
        </div>

        <div class="aside-title">
          <div class="big-title">
            CMS
          </div>

          <div class="title">
            CONTENT<br />
            MANGEMEENT<br />
            SYSTEM
          </div>
        </div>
        <!-- example static menu here -->
        <perfect-scrollbar
          class="aside-menu scroll"
          style="height: calc(100vh - 234px); position: relative;"
        >
          <KTMenu></KTMenu>
        </perfect-scrollbar>

        <!-- <div class="copyright">
          <img :src="`${baseAppPath}/media/copyright.png`" />
        </div> -->
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside {
  -webkit-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  -moz-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
}

.user-btn-dd {
  position: absolute;
  display: inline-block;
  top: 28px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}
.user-btn {
  padding: 8px 10px 10px 10px;
  border-radius: 11px;
  background: #06b56f;
}

.sidebar-accent {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  z-index: 1;
}
.aside-title {
  position: relative;
  padding: 30px 40px;
  z-index: 9;

  .big-title {
    color: #1b462f;
    line-height: 50px;
    font-size: 40px;
    font-style: normal;
    font-weight: bold;
  }
  .title {
    color: #1b462f;
    line-height: 19px;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    opacity: 0.77;
  }
}
.copyright {
  padding: 20px 41px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  img {
    height: 26px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/view/layout/aside/Menu.vue";

import { LOGOUT } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,

      name: JSON.parse(JwtService.getUserInfo()).name
    };
  },
  components: {
    KTMenu
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
    });
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.replace({ name: "login" });
    },
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      // check if the left aside menu is fixed
      if (document.body.classList.contains("aside-fixed")) {
        if (this.outsideTm) {
          clearTimeout(this.outsideTm);
          this.outsideTm = null;
        }

        // if the left aside menu is minimized
        if (document.body.classList.contains("aside-minimize")) {
          document.body.classList.add("aside-minimize-hover");

          // show the left aside menu
          document.body.classList.remove("aside-minimize");
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (document.body.classList.contains("aside-fixed")) {
        if (this.insideTm) {
          clearTimeout(this.insideTm);
          this.insideTm = null;
        }

        // if the left aside menu is expand
        if (document.body.classList.contains("aside-minimize-hover")) {
          // hide back the left aside menu
          document.body.classList.remove("aside-minimize-hover");
          document.body.classList.add("aside-minimize");
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
